import { sendPlatformSuggestion } from "shared/lib/graphql"

const TqaSuggestion = () => {
  const suggestionCards = document.querySelectorAll(`li.suggestion-wrapper`)

  suggestionCards.forEach((suggestion) => {
    const { feedbackTqaType, serviceId } = suggestion.dataset


    const parent = document.querySelector(`.${feedbackTqaType}.suggestion-modal`)
    const modalClose = parent?.closest(".modal")?.querySelector(".modal-close")
    const continueButton = parent?.querySelector(".cta button")
    const inputs = Array.from(parent?.querySelectorAll(".modal-input") || [])
    let name = ""
    let description = ""

    if (!continueButton || !inputs.length || !modalClose) {
      console.error("Missing elements for tqaSuggestion")
      return
    }

    const handleInput = (e: Event) => {
      const input = e.target as HTMLInputElement
      if (input.nodeName === "INPUT") {
        name = input.value
      } else if (input.nodeName === "TEXTAREA") {
        description = input.value
      }

      if (name && description) {
        continueButton.removeAttribute("disabled")
      } else {
        continueButton.setAttribute("disabled", "true")
      }
    }

    const onContinue = async (e: Event) => {
      e.preventDefault()
      if (continueButton.getAttribute("disabled")) return

      try {
        await sendPlatformSuggestion(
          serviceId,
          feedbackTqaType,
          name,
          description
        )
        window.appendFlash("Your suggestion has been submitted.")
        for (let input of inputs) {
          (input as HTMLInputElement).value = ""
        }
        continueButton.setAttribute("disabled", "true")
        modalClose.dispatchEvent(new Event("click"))
      } catch {
        window.appendFlash(
          "Something went wrong while submitting your suggestion. Please try again later."
        )
      }
    }

    for (let input of inputs) { input.addEventListener("input", handleInput)}
    continueButton.addEventListener("click", onContinue)
  })
}

export default TqaSuggestion
